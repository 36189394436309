'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';

const EXPIRATION_DAYS = 30; // Define expiration in days
const EXCLUDED_PATHS = [
  '/auth/login',
  '/auth/register',
  '/auth/request-reset-password'
  // Add more paths here as needed
];

export function NavigationEvents() {
  const pathname = usePathname();
  const { data: session } = useSession();

  useEffect(() => {
    // console.log('Current pathname:', pathname);

    if (session) {
      if (EXCLUDED_PATHS.includes(pathname)) {
        // console.log('Excluded pathname:', pathname);
      } else {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + EXPIRATION_DAYS);
        const lastVisitedData = {
          pathname,
          expiration: expirationDate.getTime()
        };
        localStorage.setItem('lastVisited', JSON.stringify(lastVisitedData));
        // console.log('Saving last visited page:', pathname);
      }
    } else {
      // console.log('No session data available');
    }
  }, [pathname, session]);

  return null;
}
