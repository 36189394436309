// Main Layout head.js
'use client';
import { useEffect, useState, useMemo } from 'react';
import NextHead from 'next/head';
import { usePathname } from 'next/navigation';
import useSWR from 'swr';

const fetcher = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch cName Brand Data');
  }
  return response.json();
};

export default function HeadComponent() {
  const pathname = usePathname();
  const defaultFavicon = '/Favicon.ico';
  const defaultMetaTitle = 'Classified Billing';
  const defaultMetaDescription =
    'An all-in-one invoicing solution, specially crafted for freelancers and small businesses.';
  const [hostname, setHostname] = useState(null);

  // Detect hostname only on the client
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentHostname = window.location.hostname;
      // Skip fetching on localhost
      if (currentHostname !== 'localhost' && currentHostname !== '127.0.0.1') {
        setHostname(currentHostname);
      }
    }
  }, []);

  // Fetch cName Brand Data only if hostname is set and valid
  // const { data: cnameBrandData, error: cnameBrandDataError } = useSWR(
  //   hostname ? `/api/get-domain-by-hostname?hostname=${hostname}` : null,
  //   fetcher,
  // );

  const { data: cnameBrandData, error: cnameBrandDataError } = useSWR(
    hostname ? `/api/get-domain-by-hostname?hostname=${hostname}` : null,
    (url) =>
      fetch(url, { cache: 'no-store' }) // Prevent fetch API from using browser cache
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch cName Brand Data');
          }
          return response.json();
        }),
    {
      revalidateOnFocus: true, // Revalidate data when the user focuses on the page
      revalidateOnReconnect: true, // Revalidate data when the network reconnects
      refreshInterval: 0, // Disable periodic refreshing
    },
  );

  // Check if a custom domain is active
  const isCustomDomain = useMemo(
    () =>
      cnameBrandData &&
      hostname &&
      hostname.includes(cnameBrandData.domainName),
    [hostname, cnameBrandData],
  );

  // Compute Meta Title and Description
  const metaTitle = useMemo(() => {
    const pageName = pathname.split('/').filter(Boolean).pop() || 'Home';
    const formattedPageName = pageName
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());

    return `${
      isCustomDomain ? cnameBrandData?.cnameBrandTitle : defaultMetaTitle
    } | ${formattedPageName}`;
  }, [isCustomDomain, cnameBrandData, defaultMetaTitle, pathname]);

  const metaDescription = useMemo(() => {
    return isCustomDomain
      ? cnameBrandData?.cnameBrandDescription
      : defaultMetaDescription;
  }, [isCustomDomain, cnameBrandData, defaultMetaDescription]);

  // Compute Favicon URL
  const faviconUrl = useMemo(() => {
    return isCustomDomain && cnameBrandData?.cnameBrandLogo
      ? cnameBrandData.cnameBrandLogo
      : defaultFavicon;
  }, [isCustomDomain, cnameBrandData, defaultFavicon]);

  // Compute Favicon URL with cache-busting
  // const faviconUrl = useMemo(() => {
  //   const baseUrl =
  //     isCustomDomain && cnameBrandData?.cnameBrandLogo
  //       ? cnameBrandData.cnameBrandLogo
  //       : defaultFavicon;
  //   const cacheBuster = `?v=${new Date().getTime()}`;
  //   return `${baseUrl}${cacheBuster}`;
  // }, [isCustomDomain, cnameBrandData, defaultFavicon]);

  // Log error if fetching fails
  if (cnameBrandDataError) {
    console.error(
      'Error fetching cName Brand Data:',
      cnameBrandDataError.message,
    );
  }

  return (
    <NextHead>
      {/* Meta Title */}
      <title>{metaTitle}</title>

      {/* Meta Description */}
      <meta name="description" content={metaDescription} />

      {/* Favicon */}
      <link rel="icon" href={faviconUrl} />
    </NextHead>
  );
}
